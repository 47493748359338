<!--
/**
  退款管理列表 
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="退款列表"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <!-- <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template> -->
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { cSideDriverAccount } from '@/service/apis';

  export default {
    name: 'CSideDriverAccountsList',

    data() {
      return {
        id: '',
        dictionary: {},
        polymerizationInfo: {},
        pageButtons: [],
        tableData: [],

        tableColumns: [
          { label: '充电单号', prop: 'tradeSeq', minWidth: '200px' },
          { label: '充电用户', prop: 'userAccount', minWidth: '200px' },
          { label: '终端编码', prop: 'gunCode', minWidth: '200px' },
          { label: '商户号名称', prop: 'machName', minWidth: '200px' },
          { label: '商户订单号', prop: 'payRespNo', minWidth: '260px' },
          { label: '退款原因', prop: 'reason', minWidth: '100px' },
          { label: '退款金额', prop: 'refundAmt', minWidth: '100px' },
          {
            label: '退款状态',
            prop: 'status',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const statusName = this.statusData.filter(item => item.id === row.status)[0].name;
                return <span>{statusName}</span>;
              },
            },
          },
          { label: '备注', prop: 'remark', minWidth: '200px' },
        ],

        tableOperateButtons: [
          {
            enabled: () => code('finance:account:refund:pass'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status !== 1}
                onClick={() => {
                  this.refundStatus(row, 1);
                }}>
                通过
              </ykc-button>
            ),
          },
          {
            enabled: () => code('finance:account:refund:refuse'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status !== 1}
                onClick={() => {
                  this.refundStatus(row, 2);
                }}>
                拒绝
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          tradeSeq: '',
          gunCode: '',
          userAccount: '',
          payRespNo: '',
          machName: '',
          status: '',
        },
        statusData: [
          { id: 1, name: '退款中' },
          { id: 2, name: '已退款' },
          { id: 3, name: '已拒绝' },
        ],
      };
    },
    created() {
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '充电用户',
            placeholder: '请输入充电用户',
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '退款状态',
            placeholder: '请选择退款状态',
            data: this.statusData,
          },
          {
            comName: 'YkcInput',
            key: 'payRespNo',
            label: '商户订单号',
            placeholder: '请输入商户订单号',
          },
          {
            comName: 'YkcInput',
            key: 'machName',
            label: '商户号名称',
            placeholder: '请输入商户号名称',
          },
        ];
      },
    },
    methods: {
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 退款审核 */
      refundStatus(row, type) {
        const txt = type === 1 ? '通过' : '拒绝';
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: `是否确定${txt}该退款？`,
          onCancel: dialogDone => {
            dialogDone(); // 隐藏弹窗
          },
          onConfirm: dialogDone => {
            cSideDriverAccount.auditApplyRefund({ id: row.id, type }).then(res => {
              dialogDone(); // 隐藏弹窗
              this.searchTableList();
            });
          },
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const params = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        cSideDriverAccount.refundList(params).then(res => {
          console.log(res);
          this.tableData = res?.records || [];
          this.pageInfo.total = res?.total || 0;
        });
      },

      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
